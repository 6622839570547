<template>
    <div class="add box_b">
        <!--  -->
        <div class="add_main box_b">
            <div class="vue_title">
                <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
                <p> {{id?'修改文档':'添加文档'}}</p>
            </div>
            <!--  -->
            <div class="add_info box_b" v-loading="loading">
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>标题</div>
                    <div class="t_right">
                        <el-input v-model="info.title" size='small' clearable placeholder="请输入文档标题"></el-input>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>点击量</div>
                    <div class="t_right">
                        <el-input v-model="info.hits" size='small' @input="limitNum" clearable placeholder="请输入点击量"></el-input>
                    </div>
                </div>

                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>所属分类</div>
                    <div class="t_right_select">
                        <el-cascader style="width: 100%;" v-model="info.category_id" :options="classifyOptions" :props="prop" :show-all-levels="false" size='small' placeholder="请选择分类"></el-cascader>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>是否推荐</div>
                    <div class="t_right" style="padding-bottom: 6px;">
                        <el-radio v-model="info.is_recommend" :label="1">是</el-radio>
                        <el-radio v-model="info.is_recommend" :label="2">否</el-radio>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>文章内容</div>
                    <div class="edit">
                        <div style="border: 1px solid #DCDFE6;">
                            <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 350px; width: 100%; overflow-y: hidden;" v-model="info.content" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>状态</div>
                    <div class="t_right" style="padding-bottom: 6px;">
                        <el-switch v-model="info.status" :active-value='1' :inactive-value='0'></el-switch>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="dialog-footer">
                <el-button type="primary" size='small' @click="confirm" :loading="btnLoading">确 定</el-button>
                <el-button size='small' @click="chongzhi">重 置</el-button>
            </div>

        </div>
    </div>
</template>
  
  <script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    name: "notice_add",
    components: {
        Editor,
        Toolbar
    },
    data() {
        return {
            loading: false,
            btnLoading: false,
            info: {
                title: "",
                is_recommend: 1,
                hits: "",
                status: 1,
                category_id: "",
                content: "",
            },
            id: "",
            editor: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 继续其他菜单配置...
                }
            },
            mode: 'default', // or 'simple'
            // 
            classifyOptions: [],
            prop: {
                value: 'id',
                label: 'name',
                expandTrigger: 'hover',
                children: 'children',
                checkStrictly: true,
                emitPath: false
            },
        };
    },

    created() {
        this.getCategory()
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        // 分类
        getCategory() {
            var params = {
                parent_id: 10
            }
            this.$helpApi.materialCategory(params).then(res => {
                if (res.code == 1000) {
                    let arr = res.result || []
                    arr.forEach(ele => {
                        if (ele.children.length == 0) {
                            delete ele.children
                        } else {
                            ele.children.forEach(val => {
                                if (val.children.length == 0) {
                                    delete val.children
                                }
                            })
                        }
                    });
                    this.classifyOptions = arr
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 富文本上传图片
        update(file, insertFn) {
            var that = this;
            let files = event.target.files[0];
            let form = new FormData();
            form.append("file", files);
            that.$publicApi.uploadFile(form).then(res => {
                if (res.code == 1000) {
                    insertFn(res.result.url, file.name, res.result.url)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        confirm() {
            if (this.info.title == '') {
                this.$errMsg('请输入标题')
                return
            }
            if (this.info.cate_id == '') {
                this.$errMsg('请选择分类')
                return
            }
            if (this.info.hits == '') {
                this.$errMsg('请输入点击量')
                return
            }
            if (this.$testVerify.isContent(this.info.content)) {
                this.$errMsg('请输入文章内容')
                return
            }
            // 修改
            if (this.id) {
                this.edit()
            } else {
                // 添加
                this.add()
            }
        },
        // 添加
        add() {
            this.btnLoading = true;
            let obj = JSON.parse(JSON.stringify(this.info))
            if (obj.hits == '') {
                obj.hits = 0
            }
            var params = {
                ...obj
            }
            this.$helpApi.documentListAdd(params).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/help/document')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },
        edit() {
            this.btnLoading = true;
            let obj = JSON.parse(JSON.stringify(this.info))
            if (obj.hits == '') {
                obj.hits = 0
            }
            var params = {
                ...obj
            }
            this.$helpApi.documentListEdit(params, this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/help/document')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },

        getInfo() {
            this.loading = true;
            this.$helpApi.documentListInfo(this.id).then(res => {
                if (res.code == 1000) {
                    let obj = res.result
                    this.info = {
                        title: obj.title,
                        is_recommend: obj.is_recommend,
                        hits: obj.hits,
                        status: obj.status,
                        category_id: obj.category_id,
                        content: obj.content,
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.$errMsg(res.message)
                }
            })
        },
        chongzhi() {
            this.info = {
                title: "",
                is_recommend: 1,
                hits: "",
                status: 1,
                category_id: "",
                content: "",
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        limitNum(e) {
            this.info.hits = e.replace(/[^0-9]/g, '')
        },
        back() {
            this.$router.back()
        },
    },
}
  </script>
<style  lang="scss">
/* 去掉级联选择器 单选框 点击名字可选择 */
.el-cascader-panel {
    .el-radio {
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .el-radio__input {
        visibility: hidden;
    }
    .el-cascader-node__postfix {
        top: 10px;
    }
}
</style>
  <style lang="scss" scoped>
// @import "../../assets/style/common.less";

.add {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .add_main {
        width: 100%;
        height: 100%;
    }

    .sms_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f5fa;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .add_info {
        width: 100%;
        height: calc(100% - 90px);
        padding: 15px 0;
        overflow-y: auto;

        .info_text {
            width: 100%;
            padding: 12px 0 0;
            overflow: hidden;
            display: flex;
            .switch {
                width: 90px;
                text-align: right;
                margin-right: 14px;
            }
            .edit {
                width: 1000px;
                margin-left: 10px;
                padding-top: 6px;
                position: relative;
                z-index: 1000;
            }
            .t_left {
                float: left;
                width: 110px;
                line-height: 32px;
                text-align: right;
            }
            .t_right {
                float: left;
                width: 500px;
                margin-left: 12px;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
                .r_txt {
                    padding-left: 10px;
                }
                .el-select {
                    width: 100%;
                }
            }
            .t_right_select {
                float: left;
                width: 500px;
                margin-left: 12px;
                .el-select {
                    width: 100%;
                }
            }
            .radio {
                padding-left: 10px;
                display: flex;
                align-items: center;
                height: 32px;
            }
        }
    }
}
.dialog-footer {
    padding-left: 50px;
    padding-bottom: 20px;
}
.box_b {
    box-sizing: border-box;
}
.f_s2 {
    font-size: 14px;
    line-height: 20px;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.color1 {
    color: #e51d41;
}

.color6 {
    color: #606266;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

::v-deep .el-upload {
    width: 100%;
    height: 100%;
}
</style>
  